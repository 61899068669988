<template>
  <footer class="text-muted">
    <div class="container">
      <p class="float-right">
        <a href="#">回到顶部</a>
      </p>
      <p>欢迎使用司马彦在线视频课程系统，书法交流和学习的平台</p>
      <p>备案号</p>
    </div>
  </footer>
</template>

<script>

  export default {
    name: 'theFooter',
  }
</script>
